<template>
  <div class="basePeopleContainer">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>
      <div class="banner">
          <h5 style="font-size:46px;font-weight:600;padding-bottom:40px">基础人事系统</h5>
          <button class="buttonShow" @click="$router.push('/freeTrial')">立即预约演示</button>
      </div>

      <div class="mainFunction">
        <IntroduceCare
          :fontDesc="mainFunction"
          class="mainFunctionCard"
        ></IntroduceCare>
      </div>

      <div class="systemArchitecture">
          <h4 class="descH4">系统功能图</h4>
          <span class="line"></span>
          <img src="@/assets/product/basePeople/frame.png" alt="" style="width:982px;margin-top:30px">
      </div>

      <div class="systemValue">
          <h5 class="descH4">系统价值优势</h5>
          <span class="line"></span>
          <div class="systemValueContent">
            <div v-for="(item,index) in valueList" :key="index" class="valueItem">
              <div class="valueItemMask"></div>
              <div class="valueTitle">{{item.title}}</div>
              <div class="valueLine"></div>
              <div class="valueText">{{item.conten}}</div>
            </div>
          </div>
      </div>

      <Foot></Foot>
    </div>

    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
export default {
  name: 'ProductBasePeople',
  data () {
    return {
      mainFunction: [
        {
          title: '基础人事管理系统',
          fontLeft: false,
          p: [
            {
              desc: '以用户组织岗位体系、人员信息库为基础，实现不同类别人员入转调离全生命周期基础人事业务管理，推动人事管理理念更新、模式变革、流程优化，构建用户智慧HR管理体系。'
            }
          ],
          src: require('@/assets/product/basePeople/personalIndex.png')
        }
      ],
      valueList: [
        { title: '全域员工信息库', conten: '通过统一规范数据标准、分级数据管理、规范数据过程、保证数据安全、共享数据，搭建符合单位统一数据治理框架的员工信息库。' },
        { title: '全生命周期业务流', conten: '按照单位不同人员分类，实现其从入职到离职退休的全生命周期的发展管理，展示员工成长轨迹，为人才培养提供依据。' },
        { title: '智能盘点与决策分析', conten: '发挥数据价值，构建决策支持模型，让单位领导实时动态掌握全单位师资力量、学科建设、发展趋势。' }
      ]
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moProduct/moBasePeople')
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped lang='scss'>
*{
    margin: 0;
    padding: 0;
}
img {
    object-fit: cover;
}
button:hover{
    cursor: pointer;
}
.line{
    display: inline-block;
    width: 40px;
height: 6px;
background: #D70C0C;
border-radius: 3px;
}
.buttonShow:hover{
box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
}
.descH4{
font-size: 36px;
font-weight: 500;
color: #222222;
padding: 60px 0 20px;
}
.basePeopleContainer{
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
.banner{
    width: 100%;
    height: 460px;
    background: url('~@/assets/product/basePeople/banner.png') center no-repeat;
    background-size: cover;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.banner button{
width: 200px;
height: 60px;
background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
border-radius: 34px;
border: 0;
color: #fff;
font-size: 16px;
}

.systemArchitecture{
    width: 100%;
height: 813px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}
.mainFunction{
   width: 100%;
height: 450px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .mainFunctionCard .CareContent{
    padding: 45px 0 40px;
}
::v-deep .mainFunctionCard .CareContent .right img{
width: 560px;
}

.systemValue{
      width: 100%;
height: 480px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}
.systemValueContent{
  width: 1200px;
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
}
.valueItem{
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
  margin-right: 24px;
  height: 240px;
  border-radius: 10px;
  padding: 30px;
  background-color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  .valueTitle{
    font-weight: 500;
    font-size: 24px;
    color: #222222;
    padding-top: 9px;
  }
  .valueLine{
    width: 30px;
    height: 4px;
    margin: 14px 0;
    background: #D70C0C;
  }
  .valueText{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #444;
    text-align: center;
  }
}
.valueItemMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.valueItem:last-child{
  margin-right: 0px;
}
.valueItem:hover{
  box-shadow: 0px 8px 20px 0px rgba(173,0,0,0.3);
    background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  .valueItemMask{
    display: block;
    background: url('~@/assets/hoverBg.png') center no-repeat;
    background-position: top left;
  }
  .valueTitle{
    color: #fff;
  }
  .valueLine{
    background-color: #fff;
  }
  .valueText{
    color: #fff;
  }
}
</style>
